import { useEffect, useMemo, useState } from 'react'

import { User } from '@firebase/auth'
import useDebounceCallback from '@hooks/useDebounceCallback'
import { useProfileInformation } from '@features/profile/hooks/useProfileInformation'

import {
  getFirstActivation,
  selectMaxBoardsMembers,
  selectNumberOfSponsoredBoards,
} from 'selectors'

import { identifyUser, updateUserProperties } from 'api/AnalyticsAPI'
import { useUserEmailPreferences } from '@features/account/EmailPreferences/useUserEmailPreferences'
import { getReverseAnalyticsGlobalParams } from '@features/reverseTrial/heplers/getReverseAnalyticsGlobalParams'
import { useSelectorFactory } from './useSelectorFactory'
import useGeoLocation from './useGeoLocation'

const useIdentifyAnalyticsUser = (user: User | null) => {
  const { geoData } = useGeoLocation()
  const {
    profile,
    loaded,
    isGoogleAccountConnected,
    isPayPalAccountConnected,
  } = useProfileInformation()
  const maxBoardsMembers = useSelectorFactory(selectMaxBoardsMembers)
  const sponsoredBoards = useSelectorFactory(selectNumberOfSponsoredBoards)
  const activation = useSelectorFactory(getFirstActivation)

  const { emailPreferences } = useUserEmailPreferences()

  const reverseAnalyticsGlobalParams = useMemo(() => {
    const reverseTrialParams = getReverseAnalyticsGlobalParams({
      activation,
      trialDaysAvailable: profile.reverseTrial?.daysAvailable,
    })
    return { ...reverseTrialParams, plan: activation?.plan }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    profile.reverseTrial?.daysAvailable,
    activation?.reverseTrial,
    activation?.expires,
    activation?.plan,
  ])

  const [identified, setIdentified] = useState<boolean>(false)

  const onProfileLoaded = useDebounceCallback(async () => {
    if (user && profile && geoData?.countryCode) {
      await identifyUser({
        user,
        maxBoardsMembers,
        sponsoredBoards,
        name: profile?.name || '',
        isGoogleAccountConnected:
          !!profile?.googleAuthorization?.access_token ||
          !!profile?.googleAuthorization?.credentials?.access_token,
        countryCode: geoData.countryCode,
        ...reverseAnalyticsGlobalParams,
      })

      setIdentified(true)
    } else {
      setIdentified(false)
    }
  }, 200)

  useEffect(() => {
    if (loaded && geoData?.countryCode) {
      onProfileLoaded()
    }

    // No need to add onProfileLoaded to avoid extra triggers
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded, geoData?.countryCode])

  useEffect(() => {
    if (user && emailPreferences) {
      updateUserProperties(user, {
        topic_1: Boolean(emailPreferences.offers),
        topic_2: Boolean(emailPreferences.tips),
        topic_3: Boolean(emailPreferences.product),
        connected_to_paypal: isGoogleAccountConnected,
        connected_to_google_sheet: isPayPalAccountConnected,
      })
    }
  }, [
    user,
    emailPreferences,
    isGoogleAccountConnected,
    isPayPalAccountConnected,
  ])

  const onReverseTriaChanged = useDebounceCallback(async () => {
    if (user) {
      updateUserProperties(user, reverseAnalyticsGlobalParams)
    }
  }, 1000)

  useEffect(() => {
    onReverseTriaChanged()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reverseAnalyticsGlobalParams])

  return useMemo(
    () => ({
      identified,
    }),
    [identified],
  )
}

export default useIdentifyAnalyticsUser
