import { memo, FC } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import clsx from 'clsx'
import {
  ButtonNew,
  Icon,
  InformationIcon,
  PaypalIcon,
  SpinnerIcon,
  WaringCircleIcon,
} from 'boards-web-ui'

import useBoard from '@hooks/useBoard'
import useConfirmDialog from '@hooks/useConfirmDialog'

import styles from './ConnectPayPalButton.module.css'

type Props = {
  isPayPalConnectionPending: boolean
  isPaypalFormConnected?: boolean
  errorMessage?: string
  merchantPaymentsReceivable?: boolean
  merchantPrimaryEmailConfirmed?: boolean

  onConnect?: () => void
  onDisconnect: () => void
}
const ConnectPayPalButton: FC<Props> = ({
  isPayPalConnectionPending,
  isPaypalFormConnected,
  errorMessage,

  merchantPaymentsReceivable,
  merchantPrimaryEmailConfirmed,
  onConnect,
  onDisconnect,
}) => {
  const { t } = useTranslation()
  const { isOwnBoard } = useBoard()
  const hasPaypalConnectionIssues =
    !merchantPaymentsReceivable || !merchantPrimaryEmailConfirmed

  const confirmDialog = useConfirmDialog()

  const handleDisconnect = () => {
    confirmDialog({
      title: t('payment_provider_disconnect_dialog_title'),
      message: (
        <Trans i18nKey={'payment_provider_disconnect_delete_dialog_subtitle'} />
      ),
      cancelText: t('action_disconnect'),
      confirmText: t('action_cancel'),
      onCancel: onDisconnect,
    })
  }

  if (!isOwnBoard) {
    return (
      <p className={styles.OnlyOwn}>
        <Icon className={styles.InfoIcon}>
          <InformationIcon />
        </Icon>
        {t('only_owners_connect_payment_provider')}
      </p>
    )
  }

  if (isPaypalFormConnected) {
    return (
      <>
        <div className={styles.Connected}>
          <Icon className={styles.Icon}>
            <PaypalIcon />
          </Icon>
          <div className={styles.IndicatorWrap}>
            <span>{t('button_paypal_label')}</span>
            <span
              className={clsx(
                styles.Indicator,
                hasPaypalConnectionIssues && styles.IndicatorWithError,
              )}
            >
              {t('label_connected')}
            </span>
          </div>
        </div>

        <div className={styles.DisconnectSection}>
          {!merchantPaymentsReceivable && (
            <div className={styles.ErrorMessage}>
              <Icon size="18px" color="#e33922">
                <WaringCircleIcon />
              </Icon>
              <Trans i18nKey={'checkout_payment_paypal_restriction_error'} />
            </div>
          )}
          {!merchantPrimaryEmailConfirmed && (
            <div className={styles.ErrorMessage}>
              <Icon size="18px" color="#e33922">
                <WaringCircleIcon />
              </Icon>
              <Trans i18nKey={'checkout_payment_paypal_email_error'} />
            </div>
          )}

          <button className={styles.DisconnectBtn} onClick={handleDisconnect}>
            Disconnect
          </button>
        </div>
      </>
    )
  }

  return (
    <div className={errorMessage ? '' : styles.ButtonOverrider}>
      <ButtonNew
        size="xl"
        errorMessage={errorMessage}
        fullWidth
        color={errorMessage ? 'red' : 'primary'}
        onClick={onConnect}
      >
        <Icon size="24px" className={styles.PaypalIcon}>
          <PaypalIcon />
        </Icon>
        &nbsp;&nbsp;
        {t('action_connect_paypal')}
        {isPayPalConnectionPending ? (
          <Icon size="24px" className={styles.SpinnerIcon}>
            <SpinnerIcon />
          </Icon>
        ) : null}
      </ButtonNew>
    </div>
  )
}

export default memo(ConnectPayPalButton)
