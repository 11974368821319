import {
  CheckoutEventNames,
  CheckoutEventsTimePeriodInterval,
  PaddleEventData,
} from '@paddle/paddle-js'
import { useAuth } from '@hooks/useAuth'
import usePaywallEvents from '@features/analytics/usePaywallEvents'
import { PLAN_NAMES } from '@features/reverseTrial/models/reverseTrialModels'
import { updateUserProperties } from '../../../../api/AnalyticsAPI'


const PeriodIntervalTo = {
  [CheckoutEventsTimePeriodInterval.DAY]: 'daily',
  [CheckoutEventsTimePeriodInterval.WEEK]: 'weekly',
  [CheckoutEventsTimePeriodInterval.MONTH]: 'monthly',
  [CheckoutEventsTimePeriodInterval.YEAR]: 'yearly',
}
const useHandleEventCallback = (
  campaignManagerId: string | null,
  closeTabAfterSubscription: boolean,
) => {
  const { paywallSubscriptionStartEvent } = usePaywallEvents()
  const { user } = useAuth()

  return (event: PaddleEventData) => {
    if (event.name === CheckoutEventNames.CHECKOUT_COMPLETED) {
      const item = event.data?.items[0]

      if (item) {
        const subscriptionCycle = item.billing_cycle
          ? PeriodIntervalTo[item.billing_cycle?.interval]
          : ''
        const productId = item.product.id
        const plan = item.product.name.toLowerCase()
        const isTrial = !!item.trial_period

        paywallSubscriptionStartEvent({
          plan,
          subscriptionCycle,
          trial: isTrial,
          productId,
          campaignManagerId: campaignManagerId || undefined,
        })
        if (user) {
          updateUserProperties(user, {
            plan,
            subscription_active: (
              plan !== PLAN_NAMES.free && plan !== PLAN_NAMES.reverseTrial
            ).toString(),
          })
        }
      }

      setTimeout(() => {
        if (closeTabAfterSubscription) {
          window.close()
          return
        }

        window.location.href = '/'
      }, 2000)
    }
  }
}

export default useHandleEventCallback
