import {
  FolderIcon,
  Grid,
  ImageIcon,
  LinkIcon,
  PageIcon,
  PdfIcon,
  PremiumIndicationIcon,
  TextCustomFieldIcon,
  VideoIcon,
  YoutubeVimeoIcon,
} from 'boards-web-ui'
import { Trans } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import useFolderEvents from '@features/analytics/useFolderEvents'
import useNodeCreatorAPI from '@features/nodeEditor/hooks/useNodeCreatorAPI'
import useNodeEditorAPI from '@features/nodeEditor/hooks/useNodeEditorAPI'
import { INodeProps } from '@features/nodeEditor/models/IUseNodeCreatorAPI'

import { IconWrapper } from '@ui/components/IconWrapper'
import Chip from '../../../../ui/components/Chip'
import { AddNewKeyOptions } from '../models'
import { ScrollAreaContainer } from '../../../../ui/models/ScrollArea'
import useAddNode from '../../nodeEditor/useAddNode'
import useScrollArea from '../../../../hooks/useScrollArea'
import useFeatureFlags from '../../../../hooks/useFeatureFlags'
import useAddContentTranslations from './useAddContentTranslations'
import { useProfileInformation } from '../../profile/hooks/useProfileInformation'
import useRouterParams from '../../../../hooks/useRouterParams'
import useUserFeatures, { Features } from '../../../../hooks/useUserFeatures'
import { Node, NodeType } from '../../../models'

const useAddFolderMenu = ({
  onMenuItemClick,
  totalBoardMembersCount,
  totalBoardEditorsCount,
}: {
  onMenuItemClick: () => void
  totalBoardMembersCount: number
  totalBoardEditorsCount: number
}) => {
  const navigate = useNavigate()
  const { folderCreateContentEvent, addContentMenuItemClick } =
    useFolderEvents()
  const { profile } = useProfileInformation()
  const { tryFree, enabled, limitReached } = useUserFeatures(
    Features.uploadVideo,
  )
  const { pageBuilderFeatureFlag, uploadVideoEnabledFeatureFlag } =
    useFeatureFlags()
  const { scrollToBottom } = useScrollArea()
  const { boardId, folderId } = useRouterParams()
  const createNode = useNodeCreatorAPI(boardId, folderId)
  const editNode = useNodeEditorAPI(boardId, folderId)
  const {
    image,
    text,
    videoStreaming,
    videoYoutubeVimeo,
    link,
    folder,
    page,
    pdf,
    badgeNew,
  } = useAddContentTranslations()
  const {
    addFolder,
    addImages,
    addLink,
    addPdf,
    addText,
    addVideo,
    addVideoStreaming,
  } = useAddNode(
    folderId,
    (node: INodeProps, extraParams?: { [id: string]: string }) => {
      const nodeId = createNode(node)
      folderCreateContentEvent(node as Node, boardId, {
        ...extraParams,
        board_members_count: totalBoardMembersCount.toString(),
        board_editors_count: totalBoardEditorsCount.toString(),
      })
      scrollToBottom(ScrollAreaContainer.FOLDER)

      return nodeId
    },
    editNode,
  )
  const showTryFreeBadge = tryFree && !limitReached

  const closeMenu =
    (
      callback: () => void,
      type: NodeType,
      isTryFree = false,
      isBlocked = false,
    ) =>
    () => {
      if (onMenuItemClick) onMenuItemClick()
      callback()
      addContentMenuItemClick(type, isTryFree, isBlocked)
    }

  const options: AddNewKeyOptions = [
    {
      title: text(),
      icon: (
        <IconWrapper>
          <TextCustomFieldIcon />
        </IconWrapper>
      ),
      onClick: closeMenu(addText, NodeType.TEXT),
    },
    {
      title: image(),
      icon: (
        <IconWrapper>
          <ImageIcon />
        </IconWrapper>
      ),
      onClick: closeMenu(addImages, NodeType.IMAGE),
    },
    {
      title: link(),
      icon: (
        <IconWrapper>
          <LinkIcon />
        </IconWrapper>
      ),
      onClick: closeMenu(addLink, NodeType.LINK),
    },
    {
      title: videoStreaming(),
      chip: (
        <Grid rowGap={5}>
          {(!enabled || tryFree) && (
            <IconWrapper>
              <PremiumIndicationIcon />
            </IconWrapper>
          )}
          {showTryFreeBadge && (
            <Chip blue>
              <Trans i18nKey={'badge_try_free'} />
            </Chip>
          )}
          {!showTryFreeBadge && !profile.uiState.videoHintShown && (
            <Chip blue>
              <Trans i18nKey={'badge_new'} />
            </Chip>
          )}
        </Grid>
      ),
      icon: (
        <IconWrapper>
          <VideoIcon />
        </IconWrapper>
      ),
      hidden: !profile.plan.monetized || !uploadVideoEnabledFeatureFlag,
      onClick: closeMenu(
        addVideoStreaming,
        NodeType.VIDEO_STREAMING,
        showTryFreeBadge,
        !enabled || tryFree,
      ),
    },
    {
      title: pdf(),
      icon: (
        <IconWrapper>
          <PdfIcon />
        </IconWrapper>
      ),
      onClick: closeMenu(addPdf, NodeType.PDF),
    },
    {
      title: videoYoutubeVimeo(),
      icon: (
        <IconWrapper>
          <YoutubeVimeoIcon />
        </IconWrapper>
      ),
      onClick: closeMenu(addVideo, NodeType.VIDEO),
    },
    {
      title: folder(),
      icon: (
        <IconWrapper>
          <FolderIcon />
        </IconWrapper>
      ),
      addSeparatorAbove: true,
      onClick: closeMenu(() => addFolder(), NodeType.FOLDER),
    },
    {
      title: page(),
      chip: profile.uiState.pagesHintShown ? undefined : (
        <Chip blue>{badgeNew()}</Chip>
      ),
      icon: (
        <IconWrapper>
          <PageIcon />
        </IconWrapper>
      ),
      hidden: !pageBuilderFeatureFlag,
      onClick: closeMenu(() => navigate('new-page'), NodeType.PAGE),
    },
  ]

  return options.filter(({ hidden }) => !hidden)
}

export default useAddFolderMenu
