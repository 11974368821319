import React, { PropsWithChildren, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import {
  ButtonIcon,
  ButtonNew,
  CloseIcon,
  EditIcon,
  ErrorIndicator,
  MoreIcon,
  PremiumCrownIcon,
  ShareIcon,
  Tooltip,
  TooltipContent,
  TooltipPositions,
  useDialog,
} from 'boards-web-ui'

import { DropDown, DropDownPosition } from '@ui/components/DropDown'
import useBoard from '@hooks/useBoard'
import { usePreviewModalSaveActions } from '@features/nodePreview/components/usePreviewModalSaveActions'
import { BoardMode } from '@features/board/models/nodes'
import useBoardMode from '@features/board/hooks/useBoardMode'
import { IconWrapper } from '@ui/components/IconWrapper'
import { PaywallSourceEnum } from '@features/analytics/useAccountSubscriptionEvents'
import usePaywall from '@hooks/usePaywall'
import { useReverseTrialContext } from '@features/reverseTrial/ReverseTrialContext'
import clsx from 'clsx'
import Grid from '../../../../elements/Grid'
import styles from './PreviewModal.module.css'
import PreviewModalHeader from './PreviewModalHeader'
import { ReactComponent as SuccessIcon } from '../../../../icons/ActionSuccess.svg'
import { ReactComponent as PrevIcon } from '../icons/Prev.svg'
import { ReactComponent as NextIcon } from '../icons/Next.svg'
import { Folder, NodeType } from '../../../models'
import PreviewModalBody from './PreviewModalBody'
import { NodeActionsMenu } from './NodeActionsMenu'
import usePreviewNode from '../hooks/usePreviewNode'

type Props = PropsWithChildren<{
  nid: string
  centered?: React.ReactNode
  copyText: string
  onCopyClick: () => void
  prev?: () => void
  next?: () => void
  folder?: Folder
  isSharedFolderMode?: boolean
  canCopyContentOfSharedFolder?: boolean
  sharedBoardId?: string
  sharedFolderId?: string
  missedIndicator?: boolean
}>

const PreviewModal: React.FC<React.PropsWithChildren<Props>> = ({
  nid,
  centered,
  copyText,
  onCopyClick,
  next,
  prev,
  children,
  folder,
  isSharedFolderMode,
  canCopyContentOfSharedFolder,
  sharedBoardId,
  sharedFolderId,
  missedIndicator,
}) => {
  const { t } = useTranslation()
  const {
    isReadOnly: isReadOnlyBoard,
    board,
    isPageBoard,
    isActive,
  } = useBoard()
  const mode = useBoardMode()
  const { isFreePlanUser } = useReverseTrialContext()

  const isReadOnly = isSharedFolderMode && folder ? true : isReadOnlyBoard
  const isPage = isSharedFolderMode && folder ? folder?.isPage : isReadOnlyBoard

  const shareAllowed =
    isSharedFolderMode && folder ? false : board?.options?.shareAllowed

  const { node, edit } = usePreviewNode(
    nid,
    folder,
    isSharedFolderMode,
    sharedBoardId,
    sharedFolderId,
  )
  const { openPaywall } = usePaywall()

  const { canCopy, onSaveActionClick } = usePreviewModalSaveActions({
    nodeId: node?.source.id,
    nodeType: node?.source.type,
  })

  const { close } = useDialog()

  const [isOpen, setIsOpen] = useState(false)

  if (!node) return null

  const onPrev = () => prev && prev()

  const onNext = () => next && next()

  let shareToastTextId = ''

  switch (node.node.type as unknown as NodeType) {
    case NodeType.TEXT:
      shareToastTextId = 'toast_text_copied_to_clipboard'
      break
    case NodeType.IMAGE:
      shareToastTextId = 'toast_image_copied_to_clipboard'
      break
    default:
      shareToastTextId = 'toast_link_copied_to_clipboard'
  }

  const threeHeaderStyle = {
    gridTemplateColumns: '1fr 119px minmax(300px, 1fr)',
  }

  const isLimitedPageNode = node.node.type === NodeType.PAGE && isFreePlanUser

  const shouldShowActivateButton =
    isLimitedPageNode && (isPageBoard || !isActive)

  const shouldShowEditActionButton =
    (!isReadOnly && !isPage) || (shouldShowActivateButton && !isReadOnly)

  return (
    <div className={styles.Root}>
      <PreviewModalHeader
        style={centered ? threeHeaderStyle : undefined}
        onClick={close}
      >
        <Grid
          rowGap={20}
          columnGap={20}
          height="38px"
          justifyContent="flex-start"
        >
          <ButtonIcon
            onClick={close}
            size={38}
            round
            variant={'white-transparent'}
          >
            <IconWrapper>
              <CloseIcon />
            </IconWrapper>
          </ButtonIcon>

          <span className={styles.Title}>{node.node.title}</span>
        </Grid>
        {centered}

        {!isSharedFolderMode && (
          <Grid rowGap={10} height="38px" justifyContent="flex-end">
            {shouldShowEditActionButton && (
              <ButtonNew
                color="white-transparent"
                startIcon={
                  <IconWrapper className={styles.Icon}>
                    {isLimitedPageNode ? <PremiumCrownIcon /> : <EditIcon />}
                  </IconWrapper>
                }
                onClick={() =>
                  isLimitedPageNode
                    ? openPaywall(PaywallSourceEnum.EDIT_PAGE)
                    : edit()
                }
              >
                <Trans i18nKey={'action_edit'} />
              </ButtonNew>
            )}

            {shareAllowed && (
              <Tooltip
                position={
                  isReadOnly || isPage
                    ? TooltipPositions.LEFT
                    : TooltipPositions.BOTTOM
                }
                data={
                  <TooltipContent icon={<SuccessIcon />} feedback>
                    <Trans i18nKey={shareToastTextId} />
                  </TooltipContent>
                }
                feedback
              >
                <ButtonNew
                  color="white-transparent"
                  startIcon={
                    <IconWrapper className={styles.Icon}>
                      <ShareIcon />
                    </IconWrapper>
                  }
                  onClick={onCopyClick}
                >
                  <span className={styles.CopyText}>{copyText}</span>
                </ButtonNew>
              </Tooltip>
            )}

            {mode === BoardMode.offline ? null : (
              <DropDown
                position={DropDownPosition.right}
                isOpen={isOpen}
                onToggle={setIsOpen}
                className={styles.MenuActions}
                content={
                  <NodeActionsMenu
                    node={node.source}
                    onItemClick={close}
                    setMenuOpen={setIsOpen}
                    isReadOnly={isReadOnly}
                  />
                }
              >
                <ButtonIcon size={38} round variant={'white-transparent'}>
                  <IconWrapper>
                    <MoreIcon />
                  </IconWrapper>
                </ButtonIcon>
              </DropDown>
            )}
          </Grid>
        )}
      </PreviewModalHeader>

      <PreviewModalBody
        withScroll={[
          NodeType.PDF,
          NodeType.TEXT,
          NodeType.PARAGRAPH,
          NodeType.PAGE,
          NodeType.VIDEO_STREAMING,
        ].includes(node.source.type)}
        onClick={close}
      >
        <div onClick={(e) => e.stopPropagation()}>
          <ButtonIcon
            className={styles.PrevIcon}
            size={46}
            round
            onClick={onPrev}
            disabled={!prev || shouldShowActivateButton}
            variant={'white-transparent'}
          >
            <PrevIcon />
          </ButtonIcon>
        </div>

        {missedIndicator ? (
          <ErrorIndicator
            className={clsx(
              styles.Banner,
              isFreePlanUser && styles.DisabledBanner,
            )}
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()

              if (!isReadOnly) {
                edit()
              }
            }}
          >
            {t('info_missing_page_banner')}
          </ErrorIndicator>
        ) : null}

        {children}

        <div onClick={(e) => e.stopPropagation()}>
          <ButtonIcon
            className={styles.NextIcon}
            size={46}
            round
            onClick={onNext}
            disabled={!next || shouldShowActivateButton}
            variant={'white-transparent'}
          >
            <NextIcon />
          </ButtonIcon>
        </div>
      </PreviewModalBody>

      {(isSharedFolderMode && canCopyContentOfSharedFolder) ||
      shouldShowActivateButton ? (
        <div className={styles.SharedFolderModeSaveButtonContainer}>
          <div className={styles.SaveButton}>
            <ButtonNew
              size={'large'}
              color={'primary'}
              onClick={() =>
                shouldShowActivateButton
                  ? openPaywall(PaywallSourceEnum.ACTIVATE_PAGE)
                  : onSaveActionClick()
              }
              startIcon={
                !canCopy || shouldShowActivateButton ? (
                  <PremiumCrownIcon fontSize="20px" />
                ) : undefined
              }
            >
              <Trans
                i18nKey={
                  isLimitedPageNode
                    ? 'activate_dialog_button_activate'
                    : 'action_save'
                }
              />
            </ButtonNew>
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default PreviewModal
