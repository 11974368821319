import { Trans } from 'react-i18next'

export const usePaywallTitle = ({
  currentPlanTitle,
  showNewPaywallOptions,
}: {
  currentPlanTitle?: string
  showNewPaywallOptions: boolean
}) =>
  currentPlanTitle ||
  (showNewPaywallOptions ? (
    <Trans i18nKey={'paywall_title_two_tiers'} />
  ) : (
    <Trans i18nKey={'plans_page_title'} />
  ))
